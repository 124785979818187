import styled from "@emotion/styled";
import colors from "styles/colors";
export const StyledImageGalleryComponent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  &.shadow {
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2);
  }

  &.clickable {
    cursor: pointer;

    &:not(:hover) {
      transition: 0.2s all ease;
    }

    &:hover {
      transform: scale(1.05);
    }
  }

  a {
    text-decoration: none;
    color: ${colors["black"]};
  }

  .image-text {
    padding: 16px 0;
    text-align: center;
  }
`;
